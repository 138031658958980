<template>
	<div>
		<Nav @link="linkTo" :idx="4"></Nav>
		<div class="root_container">
			<div class="contactUs">联系我们</div>
			<div class="line_block"></div>
			<div class="map_box">
				<div class="mark_addr">
					<img class="chineseMap" :src="require('@/assets/img/chineseMap.png')" alt="">
					<div class="icon_mark_box" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
						<img class="icon_mark" :src="require('@/assets/img/mark.png')" alt="">
						<span class="mark_name">杭州</span>
					</div>
					<div class="showImg_box">
						<img class="showImg_img" :src="require('@/assets/img/culture_1.png')" alt="">
						<div class="triangle"></div>
					</div>
				</div>
				<div class="half_right">
					<div class="map_box flex_inline">
						<img class="map" :src="require('@/assets/img/map.png')" alt="">
						<div class="rect_box">
							<div class="name">杭州高厚智能科技有限公司</div>
							<div class="address">浙江：杭州市拱墅区东文路与新北街交叉口星享书房</div>
						</div>
					</div>
					<div class="map_box flex_inline">
						<img class="map" :src="require('@/assets/img/tel3.png')" alt="">
						<div class="rect_box">
							<div class="tel">咨询电话：0571-56978456</div>
						</div>
					</div>
					<div class="map_box flex_inline">
						<img class="map" :src="require('@/assets/img/email.png')" alt="">
						<div class="rect_box" style="border:none;">
							<div class="tel">企业邮箱：<a href="mailto:azenui@sina.com">hr@backeytech.com</a></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
import Nav from '@/components/Nav'
import Footer from '@/components/Footer'
import $ from 'jquery'

export default {
	name: 'contactUs',
	components: {
		Nav,
		Footer
	},
	data() {
		return {

		}
	},
	created() { },
	mounted() {
		window.scrollTo(0, 0);
	},
	methods: {
		linkTo(routerName) {
			this.$router.push(routerName)
		},
		mouseEnter() {
			$('.root_container .mark_addr .showImg_box').fadeIn('slow')
		},
		mouseLeave() {
			$('.root_container .mark_addr .showImg_box').fadeOut()
		}
	}
}

</script>
<style lang='less' scoped>
@media screen and (max-width: 767px) {

	// 移动端
	.root_container {
		padding: 0 15px;
		box-sizing: border-box;
		margin: 30px auto;

		.contactUs {
			font-size: 25px;
			text-align: center;
			font-family: SourceHanSansCN-Medium, SourceHanSansCN;
			font-weight: 500;
			color: #333333;
		}

		.line_block {
			margin: auto;
			margin-top: 20px;
			width: 25px;
			height: 1px;
			background: #2890FC;
		}

		.map_box {
			.mark_addr {
				position: relative;

				.chineseMap {
					width: 100%;
				}

				.icon_mark_box {
					position: absolute;
					right: 22px;
					top: 195px;
					z-index: 1;

					.icon_mark {
						width: 15px;
					}

					.mark_name {
						font-size: 12px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #000000;
					}
				}

				.icon_mark_box:hover+.showImg_box {
					display: block;
				}

				.showImg_box {
					display: none;
					position: absolute;
					top: 18px;
					right: 4px;

					.showImg_img {
						width: 210px;
						border: 5px solid #EFEFEF;
					}

					.triangle {
						margin: -4px auto;
						margin-left: 144px;
						width: 0;
						height: 0;
						border-left: 25px solid transparent;
						border-right: 25px solid transparent;
						border-top: 26px solid#EFEFEF;
					}
				}
			}
		}

		.flex_inline {
			display: flex;
		}

		.half_right {
			.map {
				width: 30px;
				height: 30px;
				padding-top: 20px;
			}

			.rect_box {
				width: 100%;
				height: 110px;
				line-height: 1.5;
				margin-left: 3px;
				padding: 20px 0;
				box-sizing: border-box;
				border-bottom: 1px solid #E5E5E5;
				font-size: 16px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #333333;

				.tel {
					margin-top: 25px;
				}
			}
		}
	}
}

@media screen and (min-width: 992px) {

	// PC端
	.root_container {
		width: 1200px;
		padding: 0 15px;
		box-sizing: border-box;
		margin: 70px auto;

		.contactUs {
			text-align: left;
			font-size: 28px;
			font-family: SourceHanSansCN-Medium, SourceHanSansCN;
			font-weight: 500;
			color: #333333;
		}

		.line_block {
			margin-left: 30px;
			margin-top: 20px;
			width: 50px;
			height: 2px;
			background: #2890FC;
		}

		.map_box {
			display: flex;

			.mark_addr {
				position: relative;

				.icon_mark_box {
					position: absolute;
					right: 100px;
					bottom: 156px;
					z-index: 1;
					cursor: pointer;

					.icon_mark {
						width: 30px;
					}

					.mark_name {
						position: relative;
						top: -10px;
						font-size: 14px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #000000;
					}
				}

				// .icon_mark_box:hover + .showImg_box {
				// 	display: block;
				// }
				.chineseMap {
					object-fit: scale-down;
				}

				.showImg_box {
					display: none;
					position: absolute;
					top: 126px;
					right: -75px;

					.showImg_img {
						width: 420px;
						border: 10px solid #EFEFEF;
					}

					.triangle {
						margin: -4px auto;
						width: 0;
						height: 0;
						border-left: 58px solid transparent;
						border-right: 58px solid transparent;
						border-top: 60px solid#EFEFEF;
					}
				}
			}
		}

		.half_right {
			flex: 1;
			margin-top: 200px;

			.map {
				width: 30px;
				height: 30px;
				padding-top: 20px;
			}

			.rect_box {
				width: 100%;
				height: 110px;
				line-height: 1.5;
				margin-left: 3px;
				padding: 20px 0;
				box-sizing: border-box;
				border-bottom: 1px solid #E5E5E5;
				font-size: 16px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #333333;

				.tel {
					margin-top: 25px;
				}
			}
		}
	}
}</style>